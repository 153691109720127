<template>
  <div class="container">
    <div class="title">
      <div class="row">
        <div class="col-sm-12">
          <h2 style="margin: 20px 0 0 0">Combine Tests</h2>
        </div>
      </div>
    </div>
    <hr />
    <el-form ref="form" label-width="80px">
      <el-form-item label="Student">
        <el-select v-model="uid" filterable @change="getTests(category)">
          <el-option
            v-for="student in students"
            :key="student.id"
            :label="student.name"
            :value="student.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Test">
        <el-select v-model="tpo" filterable @change="getTests(category)">
          <el-option
            v-for="index in 54"
            :key="index"
            :value="'TPO' + index"
            :label="'TPO' + index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Type">
        <el-radio v-model="combineType" label="1" border
          >將完整的單元合併成一份完整的模考</el-radio
        >
        <el-radio v-model="combineType" label="2" border
          >將單篇作答合併成一個完整的單元</el-radio
        >
      </el-form-item>
      <el-form-item label="Section" v-if="combineType === '2'">
        <el-select v-model="section">
          <el-option value="reading" label="Reading"></el-option>
          <el-option value="listening" label="Listening"></el-option>
          <el-option value="speaking" label="Speaking"></el-option>
          <el-option value="writing" label="Writing"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      v-if="combineType === '1'"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="80"> </el-table-column>
      <el-table-column prop="user_exam_id" label="ID" width="100">
      </el-table-column>
      <el-table-column prop="name" label="Student"></el-table-column>
      <el-table-column prop="reading_score" label="Reading">
        <template slot-scope="scope">
          <b class="text-success">{{ scope.row["reading_score"] }}</b>
        </template>
      </el-table-column>
      <el-table-column prop="listening_score" label="Listening">
        <template slot-scope="scope">
          <b class="text-success">{{ scope.row["listening_score"] }}</b>
        </template>
      </el-table-column>
      <el-table-column prop="speaking_score" label="Speaking">
        <template slot-scope="scope">
          <b class="text-success">{{ scope.row["speaking_score"] }}</b>
        </template>
      </el-table-column>
      <el-table-column prop="writing_score" label="Writing">
        <template slot-scope="scope">
          <b class="text-success">{{ scope.row["writing_score"] }}</b>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="Date" width="200px">
        <template slot-scope="scope">
          {{ moment(scope.row.created_at).format("YYYY-MM-DD hh:mm:ss") }}
        </template>
      </el-table-column>
    </el-table>
    <el-table
      :data="tableData1"
      style="width: 100%"
      border
      v-if="combineType === '2'"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="80"> </el-table-column>
      <el-table-column prop="user_exam_id" label="ID" width="100">
      </el-table-column>
      <el-table-column prop="name" label="Student"></el-table-column>
      <el-table-column
        prop="passage"
        v-for="item in passageNum[section]"
        :key="item"
        :label="'Passage' + item"
      >
        <template slot-scope="scope">
          <b class="text-success" v-if="item == scope.row.chapter_order">{{
            scope.row[section + "_score"]
          }}</b>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="Date">
        <template slot-scope="scope">
          {{ moment(scope.row.created_at).format("YYYY-MM-DD hh:mm:ss") }}
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center" style="margin:20px 0">
      <el-button type="success" @click="combineTests" :disabled="!canCombine"
        >保 存</el-button
      >
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import moment from "moment";
import TOEFL from "@/apis/toefl";

export default {
  metaInfo() {
    return {
      title: "Combine Tests - TOEFL Mocks"
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      uid: "",
      tpo: "",
      moment,
      students: [],
      section: "reading",
      combineType: "1",
      tableData: [],
      tableData1: [],
      passageNum: {
        reading: 3,
        listening: 6,
        speaking: 6,
        writing: 2
      },
      category: "single_section",
      combineId: [],
      passages: [],
      sections: []
    };
  },
  computed: {
    canCombine() {
      let canCombine = false;
      if (this.combineType === "1") {
        if (this.combineId.length === 4) {
          if (!this.isRepeat(this.sections)) {
            canCombine = true;
          }
        }
      } else {
        let nums = 0;
        if (this.section === "reading") nums = 3;
        if (this.section === "listening") nums = 6;
        if (this.section === "speaking") nums = 6;
        if (this.section === "writing") nums = 2;

        if (this.combineId.length === nums) {
          if (!this.isRepeat(this.passages)) {
            canCombine = true;
          }
        }
      }
      return canCombine;
    }
  },
  watch: {
    section() {
      if (this.combineType === "2") {
        console.log(this.section);
        this.category = this.section;
      } else {
        this.category = "single_section";
      }
      this.getTests(this.category);
    },
    combineType() {
      console.log(this.combineType);
      if (this.combineType === "2") {
        this.category = this.section;
      } else {
        this.category = "single_section";
      }
      this.getTests(this.category);
    }
  },

  mounted() {
    this.getStudents();
    this.getTests(this.category);
  },

  methods: {
    handleSelectionChange(val) {
      let combinId = [];
      let passages = [];
      let sections = [];
      val.forEach(test => {
        combinId.push(test.user_exam_id);
        if (this.combineType === "1") {
          sections.push(test.skill_name);
        } else {
          passages.push(test.chapter_order);
        }
      });
      this.sections = sections;
      this.passages = passages;
      this.combineId = combinId;
    },
    async getStudents() {
      const students = await TOEFL.getStudents();
      this.students = students.users;
      if (this.students.length === 1) {
        this.uid = this.students[0].id;
      }
    },
    async getTests(category) {
      const res = await TOEFL.getTests({
        user_id: this.uid,
        toefl_title: this.tpo,
        category: category
      });
      if (this.combineType === "1") {
        let arr = [];
        for (let key in res) {
          arr.push(res[key]);
        }
        this.tableData = arr;
      } else {
        this.tableData1 = res;
      }
    },
    isRepeat(arr) {
      let hash = {};
      for (let i in arr) {
        if (hash[arr[i]]) {
          return true;
        }
        hash[arr[i]] = true;
      }
      return false;
    },
    async combineTests() {
      if (this.canCombine) {
        console.log(this.combineId);
        await TOEFL.combineTests({
          user_exam_ids: this.combineId
        });
        this.$message({
          message: "合併成功！",
          type: "success!"
        });
        this.$router.go(-1);
      } else {
        this.$message({
          message: "請選擇完整且不重複的項目進行合併！",
          type: "warning"
        });
      }
    }
  }
};
</script>

<style scoped></style>
